import styled from 'styled-components';
import { ReactI18NextChild, withTranslation } from 'react-i18next';

import { PrimaryProgressButton, SecondaryButton } from '@flow/buttons';
import { Modal } from '@flow/modal';
import { AddPledge } from '../../customer/tasks/AddPledge/AddPledge';

const ButtonGroup = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ModalContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

type ModalViewProps = {
  title: string;
  message: string | ReactI18NextChild;
  t: Function;
  onConfirm: Function;
  onAbort: Function;
  onClose: Function;
  props: any;
  isConfirming: boolean;
  component: any;
  customWidth?: string;
  customHeight?: string;
};

const ModalView = ({
  title,
  message,
  onConfirm,
  onAbort,
  onClose,
  isConfirming,
  customWidth = '80%',
  customHeight = '80%',
  props,
  component,
}: ModalViewProps) => (
  <Modal
    title={title}
    customWidth={customWidth}
    customHeight={customHeight}
    onClose={onClose}
  >
    <ModalBody>
      <ModalContent>{component}</ModalContent>
    </ModalBody>
  </Modal>
);

ModalView.defaultProps = {
  isConfirming: false,
};

export default withTranslation()(ModalView);
