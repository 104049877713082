import StepCircle from './StepCircle';
import clsx from 'clsx';
import DocumentStatus from './DocumentStatus';
import { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { pl } from 'date-fns/locale';
import RegistrationStatus from './RegistrationStatus';
import { withTranslation } from 'react-i18next';

const Step = ({ flowStep, flowProps, t }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const isSigning = flowStep.org.queue === 'SIGNING_DOCUMENTS';
  const isRegistration = flowStep.org.queue === 'REGISTRATION';
  const useExpandable = isSigning || isRegistration;
  const signees = flowStep?.org?.signees;
  const [activeSignee, setActiveSignee] = useState(0);
  return (
    <div
      className={
        (useExpandable && 'hover:bg-summaryHover transition-colors ') +
        'border-b'
      }
    >
      <div
        className={
          'flex flex-row items-center' +
          (useExpandable && ' cursor-pointer gap-y-4')
        }
        onClick={toggleExpanded}
      >
        <div className={!useExpandable && 'invisible'}>
          {expanded ? (
            <ChevronDown className="h-5 w-5 text-blue-500" />
          ) : (
            <ChevronRight className="h-5 w-5 text-blue-500" />
          )}
        </div>
        <div className="flex-grow grid grid-cols-3 pl-3 pb-6 pr-8 pt-6 items-center">
          <div className="flex flex-row items-center gap-2 text">
            <span className="capitalize text-sm">
              {flowStep?.org?.name?.toLowerCase()}
            </span>
          </div>
          <div className="w-full flex flex-col space-x-4 p-2 col-span-2">
            <div className="w-full border-solid border-gray-200 flex items-center space-x-4">
              {flowStep.steps.map((step: boolean, stepIndex: number) => {
                return (
                  <>
                    <StepCircle
                      step={stepIndex + 1}
                      active={step}
                      howManyDocumentsControlled={
                        flowStep?.howManyDocumentsControlled
                      }
                      howManyHasSigned={flowStep?.howManyHasSigned}
                      howManyIsTinglyst={flowStep?.howManyIsTinglyst}
                    />
                    {stepIndex < flowStep.steps.length - 1 && (
                      <div
                        className={clsx(
                          step ? 'bg-blue-500' : 'bg-gray-200 ',
                          'w-full h-1 rounded-full',
                        )}
                      ></div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {useExpandable && expanded && (
        <div className="text-sm pl-8 pb-8 flex flex-col gap-4">
          {isSigning ? (
            <>
              <p className="text-blue-500 font-bold">
                {t('signees', 'Signatarer')}
              </p>
              {signees.map((signee, index) => {
                return (
                  <DocumentStatus
                    index={index}
                    activeSignee={activeSignee}
                    setActiveSignee={setActiveSignee}
                    signee={signee}
                    documents={flowStep?.org?.pledges?.filter(
                      (pledge) => pledge.document?.approveDocument,
                    )}
                    flowProps={flowProps}
                  />
                );
              })}
            </>
          ) : (
            <>
              <p className="text-blue-500 font-bold">
                {t('documents', 'Dokumenter')}
              </p>
              {flowStep?.org?.pledges
                ?.filter((pledge) => pledge.document.approveDocument && pledge.registrationReady)
                .map((pledge) => <RegistrationStatus pledge={pledge} />)}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Step);
