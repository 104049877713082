import { useState } from 'react';
import { Plus, Minus } from 'lucide-react';
import Step from './Step';
import { PhaseMapper } from '../../utils/phaseMapper';
import ExpandCollapseButton from './ExpandCollapseButton';
import { withTranslation } from 'react-i18next';

function CaseSummary(props) {
  const minimumOrganizationsToShow = 5;
  const numberOfOrganizations = props.caseData?.data?.organizations?.length;

  const { t } = props;
  const [expand, setExpand] = useState(minimumOrganizationsToShow);

  const flowSteps = PhaseMapper(
    props.caseData?.data?.organizations,
    props.caseData,
  );

  const expandTable = () => {
    setExpand(expand + 5);
  };

  return (
    <div className="bg-blue-50 px-4 pt-8 pb-8 grid-rows-2 border-2 rounded-md border-solid border-gray-200 relative">
      <table className="w-full text-left mb-6">
        <thead>
          <tr className="grid grid-cols-3 px-8 pb-4 border-b">
            <th className="text-left col-auto text-sm">{t('organization')}</th>
            <th className="col-span-2 grid grid-cols-4">
              <th className="text-left text-sm col-span-1">{t('signees')}</th>
              <th className="text-center text-sm col-span-1">
                {' '}
                {t('documents')}
              </th>
              <th className="text-center text-sm col-span-1">
                {' '}
                {t('signing')}
              </th>
              <th className="text-right text-sm col-span-1">
                {t('tinglysing')}
              </th>
            </th>
          </tr>
        </thead>

        <tbody>
          {flowSteps
            ?.slice(0, expand)
            .map((flowStep, index) => (
              <Step flowProps={props} flowStep={flowStep} />
            ))}
        </tbody>
      </table>
      {expand < numberOfOrganizations && (
        <ExpandCollapseButton onClick={expandTable} color="#467EFF">
          <Plus />
        </ExpandCollapseButton>
      )}
      {expand >= numberOfOrganizations && numberOfOrganizations > 5 && (
        <ExpandCollapseButton
          onClick={() => setExpand(minimumOrganizationsToShow)}
          color="#467EFF"
        >
          <Minus />
        </ExpandCollapseButton>
      )}
    </div>
  );
}

export default withTranslation()(CaseSummary);
