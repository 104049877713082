import clsx from 'clsx';
import { Check } from 'lucide-react';
import { useState } from 'react';

const StepCircle = ({
  step,
  active,
  howManyDocumentsControlled,
  howManyHasSigned,
  howManyIsTinglyst,
}) => {
  return (
    <div className="flex justify-center items-center ">
      <div
        className={clsx(
          'w-8 h-8 rounded-full border-4 border-solid  flex items-center justify-center',
          active ? 'bg-blue-500' : '',
          'flex items-center justify-center relative',
        )}
      >
        {!active ? (
          <div className=" text-xs">
            {(step === 2 && (
              <StepCircleSmall
                text={
                  howManyDocumentsControlled === '0/0'
                    ? ''
                    : howManyDocumentsControlled
                }
              />
            )) ||
              (step === 3 && (
                <StepCircleSmall
                  text={howManyHasSigned === '0/0' ? '' : howManyHasSigned}
                />
              )) ||
              (step === 4 && (
                <StepCircleSmall
                  text={howManyIsTinglyst === '0/0' ? '' : howManyIsTinglyst}
                />
              ))}
          </div>
        ) : (
          <div className="w-4 h-4 flex items-center justify-center">
            <Check color={'#fff'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StepCircle;

export const StepCircleSmall = ({ text }) => {
  const numberOne = Number(text?.split('/')[0]);
  const numberTwo = Number(text?.split('/')[1]);

  const findPercentage = (numberOne, numberTwo) => {
    if (numberOne === 0) return 0;
    if (numberOne >= numberTwo) return 100;
    return (numberOne / numberTwo) * 100;
  };
  const percentage = findPercentage(numberOne, numberTwo);

  const stroke = percentage === 100 ? '#3b82f6' : '#E4E7EB';
  return (
    <div className="relative">
      <p className="absolute top-1/2 -translate-x-1/2 transform left-1/2 -translate-y-1/2 text-xs">
        {text}
      </p>
      <svg
        width="56"
        height="56"
        viewBox="-6.25 -6.25 62.5 62.5"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="progressCircle"
      >
        <circle
          r="15"
          cx="25"
          cy="25"
          stroke="#3b82f6"
          stroke-width="3"
          stroke-linecap="round"
          stroke-dashoffset="0px"
          fill="transparent"
          stroke-dasharray="94.2px"
        ></circle>
        <circle
          r="15"
          cx="25"
          cy="25"
          fill="transparent"
          stroke={stroke}
          stroke-width="4"
          stroke-dasharray="94.2px"
          stroke-dashoffset={`-${(94.2 * percentage) / 100}px`}
        ></circle>
      </svg>
    </div>
  );
};
